<template>
  <div>
    <div
      class="sign-up-header"
      style="background-image: url('images/background.jpeg')"
    >
      <div class="content">
        <h1 class="mb-5">{{ $t("button.login") }}</h1>
        <p class="text-lg">
          {{ $t("form.description") }}
        </p>
      </div>
    </div>
    <a-card
      :bordered="false"
      class="card-signup header-solid h-full"
      :bodyStyle="{ paddingTop: 0 }"
      style="max-width: 550px"
    >
      <!-- <template #title>
        <h5 class="font-semibold text-center">{{ $t("form.loginWith") }}</h5>
      </template>
      <div class="sign-up-gateways">
        <a-button>
          <img src="images/logos/logos-facebook.svg" alt="" />
        </a-button>
        <a-button>
          <img src="images/logos/logo-apple.svg" alt="" />
        </a-button>
        <a-button>
          <img src="images/logos/Google__G__Logo.svg.png" alt="" />
        </a-button>
      </div>
      <p class="text-center my-25 font-semibold text-muted">
        {{ $t("form.or") }}
      </p> -->

      <!-- Form Login -->
      <div>
        <div class="card card-container">
          <img
            id="profile-img"
            src="images/logo-laopt.png"
            class="profile-img-card"
            style="border-radius: 30px"
          />
          <a-form name="form" @submit.prevent="handleLogin">
            <a-radio-group
              default-value="phone"
              v-model="value"
              @change="onChange"
              style="display: flex; margin: 30px 0 30px 50px"
            >
              <a-radio value="phone">
                <strong>{{ $t("form.phone") }}</strong>
              </a-radio>
              <a-radio value="email">
                <strong>{{ $t("form.email") }}</strong>
              </a-radio>
            </a-radio-group>

            <div v-if="showPhone">
              <label for="phone" class="mb-10">{{ $t("form.phone") }}</label>
              <vue-tel-input
                id="phone"
                v-model="user.phone"
                v-bind="vueTel.props"
                v-validate="'required|max:25|min:11'"
                type="text"
                data-vv-rules="required"
                data-vv-as="Phone"
                name="phone"
              >
              </vue-tel-input>
              <div style="color: red">
                <span
                  v-if="errors.first('phone')"
                >
                  {{ $t("rules.requirePhone") }}
                </span>
              </div>
            </div>
            <div v-else>
              <div>
                <label for="email" class="mb-10">{{ $t("form.email") }}</label>
                <a-input
                  id="email"
                  v-model="user.email"
                  v-validate="'required|email|max:50'"
                  v-on:keyup="login"
                  type="email"
                  class="form-control"
                  name="email"
                />

                <div style="color: red">
                  <span
                    v-if="
                      errors.first('email')
                    "
                    >{{ $t("rules.enterEmail") }}</span
                  >
                  <span
                    v-else-if="
                      errors.first('email') ==
                      'The email field must be a valid email.'
                    "
                    >{{ $t("rules.emailForm") }}</span
                  >
                </div>
              </div>
            </div>
            <div>
              <label for="password" class="mb-10 mt-20">{{
                $t("form.password")
              }}</label>
              <a-input
                v-model="user.password"
                v-validate="'required|min:6|max:40'"
                v-on:keyup="login"
                type="password"
                name="password"
              />
              <div style="color: red">
                <span
                  v-if="
                    errors.first('password') ==
                    'The password field is required'
                  "
                  >{{ $t("rules.enterPassword") }}</span
                >
                <span
                  v-if="
                    errors.first('password') ==
                    'The password field must be at least 6 characters'
                  "
                  >{{ $t("rules.passwordLength") }}</span
                >
              </div>
            </div>
            <div class="mt-10">
              <a-button type="link">
                <router-link to="/forget-password">
                  {{ $t("form.forgetPassword") }}?</router-link
                >
              </a-button>
            </div>
            <div>
              <a-button
                style="
                  margin: 10px 0px 10px 0;
                  background: #008000;
                  color: #fff;
                "
                :disabled="loading"
                @click="handleLogin"
              >
                <span v-show="loading"></span>
                <span>{{ $t("button.login") }}</span>
              </a-button>
            </div>
            <div class="mb-10">
              <div v-if="message" style="color: red">
                {{ message }}
              </div>
            </div>
            <p class="font-semibold text-muted">
              {{ $t("form.dontHaveAccount") }}?
              <router-link to="/sign-up" class="font-bold text-dark">{{
                $t("button.register")
              }}</router-link>
            </p>
          </a-form>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
import User from "../models/user.model";
export default {
  name: "SignIn",
  data() {
    return {
      user: new User(),
      loading: false,
      message: "",
      rememberMe: true,
      value: "phone",
      showPhone: true,
      vueTel: {
        phone: "",
        props: {
          preferredCountries: ["LA", "CN", "TH", "VN"],
          mode: "international",
          required: true,
          validCharactersOnly: true,
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    onChange(e) {
      const login = e.target.value
      if (this.value === "phone") {
        this.showPhone = true;
        this.user.email = "";
      } else if(this.value === "email") {
        this.showPhone = false;
        this.user.phone = "";
      }
    },
    login(e) {
      if (e.keyCode === 13) {
        this.handleLogin();
      }
    },
    notification() {
      this.$notification.open({
        message: this.message,
        icon: <a-icon type="close-square" theme="filled" style="color: red " />,
        duration: 3,
      });
    },
    handleLogin(){
      this.loading = true;
      // this.user.type = 'admin'
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (this.user.phone && this.user.password) {
          this.user.email = "";
          this.user.username = this.user.phone.replace(/\s+/g, '');
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/dashboard");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
                this.message = this.$t("rules.telephone");
                this.notification();
              }
          );
        } else {
          this.user.phone = "";
          this.user.username = this.user.email
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/dashboard");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString() || response.message;
                // this.message = this.$t("rules.emailNotFound");
                this.notification();
              }
          );
        }
      });
    },
  },
};
</script>
<style scoped>
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 400px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 10px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 10px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  max-width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.login {
  margin: 0 3px 0;
}
</style>
