import { render, staticRenderFns } from "./Sign-In.vue?vue&type=template&id=145a4b65&scoped=true&"
import script from "./Sign-In.vue?vue&type=script&lang=js&"
export * from "./Sign-In.vue?vue&type=script&lang=js&"
import style0 from "./Sign-In.vue?vue&type=style&index=0&id=145a4b65&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145a4b65",
  null
  
)

export default component.exports