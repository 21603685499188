export default class User {
  constructor(
    firstname,
    lastname,
    gender,
    dob,
    passport,
    username,
    email,
    phone,
    password,
    type,
    otp,
    photo,
    photopassport,
    photovaccine,
    photortpcr,
    province_id,
    token,
    idtype,
    purpose,
    street,
    address,
    city,
    countrycode,
    visatype_id,
    workplace,
    resident,
  ) {
    this.username = username;
    this.firstname = firstname;
    this.lastname = lastname;
    this.gender = gender;
    this.dob = dob;
    this.email = email;
    this.phone = phone;
    this.passport = passport;
    this.password = password;
    this.type = type;
    this.otp = otp;
    this.photo = photo;
    this.photopassport = photopassport;
    this.photovaccine = photovaccine;
    this.photortpcr = photortpcr;
    this.province_id = province_id;
    this.token = token;
    this.idtype =idtype;
    this.purpose = purpose;
    this.street = street;
    this.address = address;
    this.city = city;
    this.countrycode = countrycode;
    this.visatype_id = visatype_id;
    this.workplace = workplace;
    this.resident = resident;
  }
}
